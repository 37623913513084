// src/components/home/GamesSection/GamesSection.jsx
import React, { forwardRef } from 'react';
import GameCard from './GameCard/GameCard';
import './GamesSection.scss';
import gameBigmax from '../../../assets/images/game-bigmax.png';
import gameEgypt from '../../../assets/images/game-egypt.png';
import gameBigFish from '../../../assets/images/game-bigfish.png';

const GamesSection = forwardRef((props, ref) => {
  const games = [
    {
      id: 1,
      title: 'Bigmax Diamonds and Wilds Casino Game',
      image: gameBigmax,
      description: 'Enter a brilliant international of gem stones and riches in Bigmax Diamonds and Wilds! This interesting slot recreation features cascading diamond symbols, expanding wilds, and more than one methods to win digital rewards thru exciting bonus features.', 
      gamePath: '/games/bigmax'
    },
    {
      id: 2,
      title: 'King of Egypt Casino Game',
      image: gameEgypt,
      description: 'Journey to ancient Egypt in this captivating slot journey! Match hieroglyphic symbols, liberate the pharaoh\'s tomb bonus game, and watch as scarab beetles rework into wilds for epic digital winning mixtures.',
       gamePath: '/games/kingegypt'
    },
    {
      id: 3,
      title: 'Big Fish Casino Game',
      image: gameBigFish,
      description: 'Make a touch in this underwater-themed slot sensation! Watch as colourful sea creatures swim throughout the reels, cause the bubble bonus spherical, and reel in large virtual prizes with the special Big Fish feature.', 
      gamePath: '/games/bigfish'
    }
  ];

  return (
    <section className="games-section" ref={ref}>
      <div className="container">
        <div className="games-section__header">
          <h2 className="games-section__title">Top Great Games</h2>
          <p className="games-section__description">
            Experience the excitement of social casino gaming with virtual money!
            Play with virtual money, engage with other players, and enjoy an immersive
            casino experience anytime, anywhere, without cash or real winnings.
          </p>
        </div>
        <div className="games-section__grid">
          {games.map(game => (
            <GameCard
              key={game.id}
              title={game.title}
              image={game.image}
              description={game.description}
              gameUrl={game.gamePath}
            />
          ))}
        </div>
      </div>
    </section>
  );
});

export default GamesSection;

