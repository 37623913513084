import React from 'react';
import './HeroSection.scss';
import './HeroContent.scss';
import { Link } from 'react-router-dom';
import heroCasinoImage from '../../../assets/images/hero-casino.webp';

const HeroSection = ({ gamesSectionRef }) => {
  const scrollToGames = (e) => {
    e.preventDefault();
    gamesSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <section className="hero-section">
      <div className="container">
        <div className="hero-section__content">
          <div className="hero-section__text">
            <h1 className="hero-section__title">
              <span>THE BEST FREE</span>
              <span>SOCIAL <span className="highlight">CASINO GAME</span></span>
              <span>Play today.</span>
            </h1>
            <p className="hero-section__subtitle">
              Welcome to mostmegagames.com - Where Every Spin Tells a Story
            </p>
            <div className="hero-section__features">
              <p className="hero-section__tagline">
                Join Canada's fastest-growing social casino community
              </p>
              <ul className="hero-section__benefits">
                <li>
                  <span className="icon">🎰</span>
                  Premium Casino Games
                </li>
                <li>
                  <span className="icon">🎁</span>
                  Free Bonuses
                </li>
                <li>
                  <span className="icon">👥</span>
                  Social Features
                </li>
              </ul>
            </div>
            <div className="hero-section__buttons">
              <button
                onClick={scrollToGames}
                className="hero-section__button hero-section__button--play"
              >
                <span className="play-icon">▶</span>
                START PLAYING
              </button>
              <Link to='/contact' className="hero-section__button">
                JOIN NOW - IT'S FREE
              </Link>
            </div>
            <small className="hero-section__disclaimer">
              For entertainment purposes only. Play responsibly.
            </small>
          </div>
          <div className="hero-section__image">
            <div className="hero-section__image-wrapper">
              <img src={heroCasinoImage} alt="Experience premium casino games" />
              <div className="hero-section__floating-elements">
                <span className="floating-chip">♠️</span>
                <span className="floating-chip">♥️</span>
                <span className="floating-chip">♣️</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;