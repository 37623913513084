// src/pages/Home.jsx
import React, { useRef, useEffect  } from 'react';
import { useLocation } from 'react-router-dom';
import HeroSection from '../components/home/HeroSection/HeroSection';
import AboutSection from '../components/home/AboutSection/AboutSection';
import WhyPlaySection from '../components/home/WhyPlaySection/WhyPlaySection';
import GamesSection from '../components/home/GamesSection/GamesSection';
import FaqSection from '../components/home/FaqSection/FaqSection';
import FeaturesSection from '../components/home/FeaturesSection/FeaturesSection';
import usePageTitle from '../hooks/usePageTitle';
const Home = () => {
  const helmet = usePageTitle('Home');
  const location = useLocation();
  const gamesSectionRef = useRef(null);

  useEffect(() => {
    if (location.hash === '#games') {
      gamesSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);
  return (
    <>
      {helmet}

      <div className="home">
        <HeroSection gamesSectionRef={gamesSectionRef} />
        <AboutSection />
        <GamesSection ref={gamesSectionRef} />
        <WhyPlaySection />
        <FaqSection />
        <FeaturesSection />
      </div>
    </>
  );
};

export default Home;