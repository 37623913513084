// src/components/layout/Footer/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import begambleawareImage from '../../../assets/images/begambleaware.png';
import gambleawareImage from '../../../assets/images/gambleaware.png';
import gamstopImage from '../../../assets/images/gamstop.png';
import eighteenPlusImage from '../../../assets/images/18-plus.png';

const Footer = () => {

const certifications = [
  { src: begambleawareImage, alt: 'Be Gamble Aware' },
  { src: gambleawareImage, alt: 'Gamble Aware' },
  { src: gamstopImage, alt: 'GamStop' },
  { src: eighteenPlusImage, alt: '18 Plus' },
  ];

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          {/* Certifications */}
          <div className="footer__certifications">
            {certifications.map((cert, index) => (
              <img 
                key={index}
                src={cert.src}
                alt={cert.alt}
                className="footer__cert-image"
              />
            ))}
          </div>

          {/* Disclaimer */}
          <div className="footer__disclaimer">
            <p>
            Disclaimer: MostMegaGames is a free-to-play social casino gaming platform designed 
              exclusively for leisure functions. All games, credit, and rewards on our platform are 
              digital and keep no actual-world monetary cost. Success in our social free casino games does not 
              mean destiny success at actual money gambling. Our platform is exactly intended for customers 
              19 years of age or older in compliance with Canadian gaming rules. By having access to our 
              offerings, you acknowledge and verify which you meet the minimal age requirement and 
              recognize that no actual cash playing takes place on this platform. We sell responsible 
              gaming practices and offer a safe, enjoyable environment for our network to revel in 
              social free casino games.
            </p>
          </div>

          {/* Contact Info */}
          <div className="footer__info">
            <h3 className="footer__info-title">CONTACT INFO</h3>
            
            <div className="footer__info-group">
              <h4>Location:</h4>
              <p>174 Maple Avenue Toronto, ON M5H 2N6 Canada</p>
            </div>

            {/* Legal Links */}
            <div className="footer__links">
              <Link to="/terms" className="footer__link">TERMS OF SERVICE</Link>
              <Link to="/privacy" className="footer__link">PRIVACY POLICY</Link>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="footer__copyright">
          <p>© {new Date().getFullYear()} mostmegagames.com MADE BY <Link to='/'>mostmegagames.com</Link></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;